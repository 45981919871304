// Smooth Scrolling
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// Sentry
import * as Sentry from '@sentry/browser';
if (!(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
  Sentry.init({
    dsn: "https://9dd3fc323fdb4c78b57246999c179c40@sentry.io/1426952"
  });
}

document.addEventListener('turbo:load', () => {

  // Smooth scroll navigation links
  document.querySelectorAll('.navigation a').forEach(element => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector(`#${e.target.dataset.targetId}`).scrollIntoView({ behavior: 'smooth' })
    })
  });

  // Modal Show Actions
  document.querySelectorAll('.modal-show').forEach(element => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      document.body.classList.add("show-modal")
      let modal = document.getElementById(e.target.dataset.modalId)
      modal.classList.add("show")
    })
  });

  // Modal Close Actions
  document.querySelectorAll('.modal-close').forEach(element => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      document.body.classList.remove("show-modal")
      e.target.closest(".demo--modal").classList.remove("show")
    })
  });

});